@import '../../../../scss/theme-bootstrap';

.smashcash-popup {
  position: relative;
  text-align: left;
  text-transform: none;
  @include breakpoint($landscape-up) {
    height: 401px;
    margin: 0 auto;
    max-width: 650px;
    overflow: hidden;
  }
  &__images {
    width: 100%;
  }
  &__content {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 55%;
    width: 50%;
    transform: translateY(-50%);
    color: $color-black;
    &--caption {
      font-family: $font--freight-book-Regular;
      font-size: 30px;
      font-weight: bold;
      line-height: 1;
      margin: 5px 0;
      @include breakpoint($portrait-up) {
        font-size: 60px;
      }
    }
    &--header {
      font-family: $font--subheading-alt;
      font-weight: bold;
      font-size: 15px;
      line-height: 1;
      @include breakpoint($portrait-up) {
        font-size: 29px;
        margin-bottom: 15px;
      }
    }
    &--subheader {
      margin: 10px 0 0;
      li {
        font-size: 11px;
        padding-bottom: 10px;
      }
      @include breakpoint($portrait-up) {
        margin: 10px 0;
        li {
          font-size: 14px;
        }
      }
    }
  }
  &__cta {
    padding-bottom: 10px;
    a {
      font-family: $font--subheading-alt;
    }
  }
}

.smashcash-overlay {
  #cboxLoadedContent {
    padding-bottom: 0;
  }
}
